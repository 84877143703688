.fm-demographics-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #000;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  margin-left: 15px;
  flex: 0 0 75px;
}

.fm-demographics-form {
  display: flex;
  margin: 15px;
}

.fm-demographics-form-label {
  line-height: 43px;
  margin-right: 10px;
  font-weight: 600;
}

.fm-demographics-form .fm-input {
  margin-right: 10px;
}

.fm-demographics-title {
  font-size: 18px;
  font-weight: 600;
  margin: 30px 15px 20px 15px;
}

.fm-demographics-brackets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 10px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.fm-demographics-brackets-bar-holder {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 5px;
  flex: 1;
}

.fm-demographics-brackets-bar {
  background: #FF206E;
  display: block;
  flex: 1;
  border-radius: 3px 3px 0 0;
}

.fm-demographics-brackets-item {
  flex: 1;
}

.fm-demographics-brackets-value {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  font-weight: 400;
}

.fm-demographics-brackets-date {
  transform: rotate(45deg);
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
  color: #999;
}

.fm-demographics-section {
  display: flex;
  flex-direction: row;
}

.fm-demographics-graph {
  flex: 1;
}

.fm-demographics-graph .recharts-wrapper, .fm-demographics-graph .recharts-wrapper svg {
  max-width: 100%
}

.fm-demographics-empty {
  color: #9C9B9D;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
  flex: 1;
}

@media (max-width: $breakpoint-lg) {
  .fm-demographics-section {
    flex-direction: column;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-demographics-brackets {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .fm-demographics-brackets-item {
    min-width: 65px;
  }
}
