.fm-top-bar {
  border-bottom: 1px solid $border-color;
}

.fm-top-bar-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction:  row;
  align-items: center;
  padding: 20px 0;
  height: 80px;
  justify-content: center;
}

.fm-top-bar-logo {
  display: block;
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-pink.svg') 0% 65% no-repeat;
  height: 40px;
  width: 100px;
  background-size: 100px;
  margin: 15px 20px;
  font-size: 0;
}
