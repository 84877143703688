.fm-data-section-title {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 15px;
}

.fm-data-item-title {
  font-weight: 600;
}

.fm-data-item {
  margin: 30px 15px;
}

.fm-data-item-endpoint {
  font-family: 'Courier', monospace;
  margin: 10px 0;
  color: $light-text-color;
  font-size: 14px;
}

.fm-data-item-endpoint-method {
  font-weight: 800;
  margin-right: 10px;
}

.fm-data-item-description {
  font-size: 14px;
}

.fm-data-section-text {
  margin: 15px;
  line-height: 22px;
}

.fm-data-section-link {
  color: $primary;
}

.fm-data-section {
  margin-bottom: 50px;
}

.fm-data-item-line-label {
  font-weight: 600;
  margin-right: 8px;
}

.fm-data-item-line-button {
  background: #fff;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: $primary;
  cursor: pointer;
}

.fm-data-item-line {
  margin: 20px 15px;
  font-size: 14px;
}

.fm-data-item-line-warning {
  margin-left: 5px;
  color: red;
}
