.fm-platform {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.fm-platform-content {
  flex: 1;
  width: 100%;
  overflow-y: scroll;
}

@media (min-width: $breakpoint-lg) {
  .fm-platform {
    flex-direction: row-reverse;
  }

  .fm-platform-content {
    height: 100%;
  }
}
