html, body {
  font-weight: 400;
  color: $text-color;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  height: 100%;
  background-color: $background-color;
  -webkit-text-size-adjust: none;
}

.fm-brand {
  font-family: 'Fugaz One', cursive;
  letter-spacing: -0.5px;
}

.fm-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#root, .fm-app {
  height: 100%;
}

.fm-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.fm-text {
  font-size: 16px;
  margin: 5px 0;
  line-height: 25px;
  display: block;
}

.fm-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 25px;
}

.fm-page-title {
  font-size: 30px;
  font-weight: 600;
  padding: 15px 0;
  margin-bottom: 10px;
  line-height: 50px;
}

.fm-page-action {
  font-size: 14px;
  background: $light-background-color;
  padding: 6px 10px;
  border-radius: 2px;
  font-weight: 500;
  color: #666;
  margin-right: 15px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
  vertical-align:top;

  &:hover {
    color: $text-color;
  }
}

.fm-page-action-loading {
  padding: 4.5px 10px;

  .fm-loading {
    min-width: 80px;
  }
}
