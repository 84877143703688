.fm-analytics-title {
  font-size: 18px;
  font-weight: 600;
  margin: 30px 15px 20px 15px;
}

// Form

.fm-analytics-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #000;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  margin-left: 15px;
  flex: 0 0 75px;
}

.fm-analytics-form {
  display: flex;
  margin: 15px;
}

.fm-analytics-form .fm-input-select {
  margin-right: 15px;
}

.fm-analytics-form .fm-input {
  margin-right: 10px;
}

.fm-analytics-form-label {
  line-height: 43px;
  margin-right: 10px;
  font-weight: 600;
}

// Bar

.fm-analytics-bar {
  background: $primary;
  border-radius: 3px;
  margin: 25px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fm-analytics-bar .fm-loading {
  margin: 35px auto;
  height: 20px;
  width: 20px;
}

.fm-analytics-bar .fm-loading .fm-loading-spinner {
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid rgb(255, 255, 255);
}

.fm-analytics-bar-item {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  flex: 0 0 25%;
}

.fm-analytics-bar-icon {
  color: #fff;
  font-size: 30px;
  margin: 0 10px;
}

.fm-analytics-bar-text {
  margin-left: 15px;
}

.fm-analytics-bar-value {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}

.fm-analytics-bar-label {
  font-size: 14px;
  color: #fff;
  opacity: 0.8;
  margin-top: 5px;
  font-weight: 500;
}

// Areas

.fm-analytics-area {
  width: 140px;
  height: 120px;
  border: 2px dashed #eee;
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 12px;
  margin: 0 15px 15px 0;
}

.fm-analytics-area-value {
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
}

.fm-analytics-areas {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.fm-analytics-area-label {
  font-size: 16px;
  opacity: 0.5;
  letter-spacing: 0.2px;
}

// Trip Breakdown

.fm-analytics-trips {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 10px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.fm-analytics-trips-bar-holder {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 5px;
  flex: 1;
}

.fm-analytics-trips-bar {
  background: #FF206E;
  display: block;
  flex: 1;
  border-radius: 3px 3px 0 0;
}

.fm-analytics-trips-item {
  flex: 1;
}

.fm-analytics-trips-value {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  font-weight: 400;
}

.fm-analytics-trips-date {
  transform: rotate(45deg);
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
  color: #999;
}

@media (max-width: $breakpoint-xl) {
  .fm-analytics-bar-item {
    flex: 0 0 50%;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-analytics-trips {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .fm-analytics-trips-item {
    min-width: 35px;
  }
}

@media (max-width: $breakpoint-xs) {
  .fm-analytics-bar-item {
    flex: 0 0 100%;
    border-bottom: 2px solid #fff;
  }

  .fm-analytics-bar-item:last-child {
    border-bottom: none;
  }

  .fm-analytics-form {
    flex-direction: column;
  }

  .fm-analytics-button {
    margin-left: 0;
    flex: 1;
  }

  .fm-analytics-form .fm-input {
    margin-right: 0;
    margin-bottom: 15px;
    flex: 1;
  }
}
