.fm-contacts-table {
  margin: 12px;
  width: 100%;
}

.fm-contacts-td {
  font-size: 16px;
  padding: 10px 0 20px 0;
}

.fm-contacts-td-light {
  font-size: 16px;
  padding: 10px 0 20px 0;
  color: $light-text-color;
}
