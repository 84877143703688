.fm-dashboard-welcome {
  padding: 20px 15px 15px 15px;
  font-size: 16px;
  line-height: 22px;
}

.fm-dashboard-bar {
  background: $primary;
  border-radius: 3px;
  margin: 15px 15px 50px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fm-dashboard-bar-loading {
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 20px;
  margin: 50px auto;
}

.fm-dashboard-bar-item {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  flex: 0 0 25%;
}

.fm-dashboard-bar-icon {
  color: #fff;
  font-size: 30px;
  margin: 0 10px;
}

.fm-dashboard-bar-text {
  margin-left: 15px;
}

.fm-dashboard-bar-value {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}

.fm-dashboard-bar-label {
  font-size: 14px;
  color: #fff;
  opacity: 0.8;
  margin-top: 5px;
  font-weight: 500;
}

@media (max-width: $breakpoint-xl) {
  .fm-dashboard-bar-item {
    flex: 0 0 33.33333%;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-dashboard-bar-item {
    flex: 0 0 50%;
  }
}

@media (max-width: $breakpoint-xs) {
  .fm-dashboard-bar-item {
    flex: 0 0 100%;
    border-bottom: 2px solid #fff;
  }

  .fm-dashboard-bar-item:last-child {
    border-bottom: none;
  }
}
