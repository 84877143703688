.fm-maps {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.fm-maps-options {
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $border-color;
  padding: 13px 13px 13px 13px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 70px;
}

.fm-maps-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-maps-message {
  font-size: 14px;
  color: $light-text-color;
  padding: 20px;
  text-align: center;
  line-height: 22px;
}

.fm-maps-options .fm-input {
  margin-right: 15px;
}

.fm-maps-options-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #000;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  flex: 0 0 75px;
}

@media (max-width: $breakpoint-md) {
  .fm-maps-options {
    flex-wrap: wrap;
    padding: 13px 13px 0 13px;
    flex: 0 0 178px;
    height: 178px;
  }

  .fm-maps-options .fm-input {
    flex: 0 0 49%;
    margin-right: 0;
    margin-bottom: 13px;
  }

  .fm-maps-options-button {
    flex: 1;
    margin-right: 0;
    margin-bottom: 13px;
  }

  .fm-maps-options .fm-input:nth-child(odd) {
    margin-right: 2%;
  }

}
