.fm-vehicle {
  padding-bottom: 50px;
}

.fm-vehicle-details-top-preview {
  width: 100px;
}

.fm-vehicle-details-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-details-top-middle {
  flex: 1;
  padding: 0 0 0 40px;
}

.fm-vehicle-details-top-right {
  display: inline-block;
}

.fm-vehicle-details-registration {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #000;
  padding: 5px 12px;
  display: inline-block;
  border-radius: 5px;
}

.fm-vehicle-details-qr {
  color: $light-text-color;
  font-size: 18px;
  margin-top: 15px;
}

.fm-vehicle-details-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-vehicle-details-status {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
}

.fm-vehicle-details-top-right {
  text-align: right;
}

.fm-vehicle-details-updated {
  color: $light-text-color;
  font-size: 14px;
  margin-top: 8px;
}

.fm-vehicle-details-imei {
  color: $light-text-color;
  font-size: 14px;
  margin-top: 8px;
}

/* CONTROLS */

.fm-vehicle-details-controls {
  padding: 25px 0 0 0;
}

.fm-vehicle-details-button {
  cursor: pointer;
  display: block;
  background: $light-background-color;
  padding: 10px 18px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 20px 0 0;
  height: 40px;
  transition: ease all 0.2s;
  text-align: center;
  flex: 1;

  &:hover {
    background: $border-color;
  }

  &:last-child {
    margin: 10px 0 0 0;
  }
}

/* RIDE */

.fm-vehicle-details-inuse {
  background: #4833d3;
  color: #fff;
  border-radius: 3px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-weight: 600;
}

/* SECTION */

.fm-vehicle-details-section {
  border-top: 1px solid $border-color;
  margin-top: 50px;
  position: relative;
  padding-bottom: 20px;
}

.fm-vehicle-details-section-title {
  width: 100px;
  background: #fff;
  text-align: center;
  margin: -10px auto 10px auto;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: $light-text-color;
}

.fm-vehicle-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.fm-vehicle-details-item-value {
  background: $light-background-color;
  padding: 10px 18px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.fm-vehicle-details-item-label {
  font-size: 16px;
  line-height: 20px;
}

.fm-vehicle-details-map {
  height: 300px;
  margin-top: 50px;
}

.fm-vehicle-details-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5px 0;
}

/* Status changes */

.fm-vehicle-changes {
  margin: 20px 0;
}

.fm-vehicle-changes-title {
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #9C9B9D;
  margin: 40px 0 20px 0;
}

.fm-vehicle-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.fm-vehicle-change-type {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
}

.fm-vehicle-change-reason {
  font-size: 14px;
  flex: 1;
  text-align: left;
  padding: 0 20px;
}

.fm-vehicle-change-time {
  color: $light-text-color;
  font-size: 14px;
}

@media (max-width: $breakpoint-sm) {
  .fm-vehicle-details-top-preview {
    display: none;
  }
  .fm-vehicle-details-top-middle {
    padding: 0;
  }
}
