/* ------ Vehicle Finder ------ */

.fm-vehicle-finder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-vehicle-finder-source {
  flex: 1;
  position: relative;
  transition: ease all 0.2s;
}

.fm-vehicle-finder-source-search {
  height: 0;
  flex: 0;
  overflow: hidden;
}

.fm-vehicle-finder-bar {
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $border-color;
  padding: 13px 13px 11px 13px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 68px;
}

.fm-vehicle-finder-bar .fm-input-container {
  margin: 0;
  flex: 1;
}

.fm-vehicle-finder-bar .fm-input {
  height: 44px;
  border-radius: 3px;
}

.fm-vehicle-finder-bar-qr {
  height: 44px;
  background: $text-color;
  width: 44px;
  margin: 0 0 0 12px;
  border-radius: 3px;
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  color: #fff;
}

.fm-vehicle-finder-search {
  padding: 0 14px;
  overflow: hidden;
  overflow-y: scroll;
  transition: ease all 0.2s;
}

.fm-vehicle-finder-search-active {
  padding: 2px 14px 225px 14px;
}

.fm-vehicle-finder-results-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 150px;
}

.fm-vehicle-finder-filters {
  height: 95px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  animation: fm-show-filters 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-show-filters {
  0% {
    height: 0;
  }
  100% {
    bottom: 95px;
  }
}

.fm-vehicle-finder-filters-statuses {
  padding: 0 8px 5px 8px;
  overflow-x: scroll;
  width: 100%;
  height: 37px;
  white-space: nowrap;
}

.fm-vehicle-finder-filters-status {
  display: inline-block;
  color: #fff;
  margin: 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 3px 6px;
}

.fm-vehicle-finder-filters-selects {
  display: flex;
}

.fm-vehicle-finder-filters .fm-input-container {
  margin: 5px 13px;
  flex: 1;
}

.fm-vehicle-finder-filters .fm-input-container + .fm-input-container {
  margin: 5px 13px 5px 0;
}

.fm-vehicle-finder-filters .fm-input-select {
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 15px;
}

/* ------ Vehicle Scanner ------ */

.fm-vehicle-scanner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $text-color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fm-vehicle-scanner-reader {
  width: 100%;
  flex: 1;
  display: flex;
}

.fm-vehicle-scanner-bar {
  padding: 13px;
}

.fm-vehicle-scanner-select {
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 15px;
  background-color: #333;
  color: #fff;
  font-weight: 600;
}

.fm-vehicle-scanner-status {
  position: absolute;
  top: 13px;
  left: 13px;
  right: 13px;
  z-index: 999;
  background: $primary;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 15px;
  line-height: 21px;
  animation: fm-show-status 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-show-status {
  0% {
    top: -50px;
  }

  100% {
    top: 10px;
  }
}





.fm-vehicles {
  padding-bottom: 50px;
}

.fm-vehicles-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fm-vehicles-search-bar .fm-input-container {
  flex: 1;
}

.fm-vehicles-search-bar-qr {
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/qr.png') 50% 50% no-repeat;
  background-size: 25px;
  height: 25px;
  width: 25px;
  opacity: 0.6;
  margin-left: 15px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

.fm-vehicles-search-bar-filter .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/filter.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 25px;
  height: 25px;
  width: 25px;
  opacity: 0.6;
  margin-left: 15px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

.fm-vehicles-search-bar-filter-inuse .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-inuse.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-available .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-available.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-unavailable .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-unavailable.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-reserved .fm-input-select, .fm-vehicles-search-bar-filter-charging .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-charging.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-maintenance .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-maintenance.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-transit .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-transit.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar-filter-demo .fm-input-select {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/admin/vehicle-demo.png');
  opacity: 1.0;
}

.fm-vehicles-search-bar .fm-input-container {
  margin: 10px 0;
}

.fm-vehicles-result-registration {
  background: #111111;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 0;
  width: 62px;
  border-radius: 3px;
  text-align: center;
}

.fm-vehicles-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid $border-color;
}

.fm-vehicles-result-qr {
  color: $light-text-color;
}

.fm-vehicles-result-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-vehicles-result-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
}

.fm-vehicles-result-imei {
  color: $light-text-color;
  font-size: 12px;
  margin-top: 5px;
}

.fm-vehicles-results {
  padding-bottom: 15px;
}

.fm-vehicles-results-results a:last-child .fm-vehicles-result {
  border-bottom: none;
}

.fm-vehicles-results-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
}

.fm-vehicles-results-more {
  cursor: pointer;
  display: block;
  background: #F8F7F9;
  padding: 10px 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 0 0 0;
  height: 40px;
  -webkit-transition: ease all 0.2s;
  transition: ease all 0.2s;
  text-align: center;
  flex: 1 1;
}
